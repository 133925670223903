<template>
  <div>
    <LayoutDataTable
      :isLoading="isLoading"
      :searchQuery="filter.searchQuery"
      :perPage="pagination.perPage"
      :meta="dataMeta"
      :currentPage="pagination.currentPage"
      :totals="pagination.totals"
      :searchPlaceholder="$t('lbl_search')"
      @setPerPage="
        (value) => ((pagination.perPage = value), (pagination.currentPage = 1))
      "
      @setCurrentPage="(value) => (pagination.currentPage = value)"
      @setSearchQuery="
        (value) => ((filter.searchQuery = value), (pagination.currentPage = 1))
      "
      ><template #cta>
        <b-button
          to="campaigns/add"
          variant="primary"
          class="ml-1"
          style="height: fit-content"
        >
          <span class="text-nowrap">{{ $t("lbl_add_new") }}</span>
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          style="height: fit-content"
          @click="isShowCheckCodeModal = true"
        >
          <span class="text-nowrap">{{ 'Kiểm tra voucher code' }}</span>
        </b-button>
      </template>
      <template #default>
        <b-table
          ref="refCampaignsTable"
          striped
          hover
          empty-text="Không có dữ liệu"
          :items="data"
          :fields="fieldsTable"
          @row-clicked="handleRowClick"
        >
          <template #cell(name)="data">
            <b>{{ data.item.name }}</b>
          </template>
          <template #cell(start_date)="data">
            <span> {{ appUtils.formatDate(data.item.start_date) }}</span>
          </template>
          // <template #cell(campaign_budget)="data">
          //   <span>{{ appUtils.formatNumber(data.item.campaign_budget) }}</span>
          // </template>
          <template #cell(end_date)="data">
            <span> {{ appUtils.formatDate(data.item.end_date) }}</span>
          </template>
        </b-table>
      </template>
    </LayoutDataTable>
    <CheckCodeModal
        :is-show-check-code-modal.sync="isShowCheckCodeModal"
    />
  </div>
</template>
<script>
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import { STATUS_RETURN_RESULT, PRODUCT_TYPES } from "@/utils/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import i18n from "@/libs/i18n";
import Vue from "vue";
import CheckCodeModal from "@/components/Campaign/CheckCodeModal.vue";

export default {
  name: "Result",
  components: {
    LayoutDataTable,
    CheckCodeModal,
  },
  data() {
    return {
      appUtils,
      isLoading: false,
      isShowCheckCodeModal: false,
      filter: {
        searchQuery: "",
        from_date: "",
        to_date: "",
        returnResultStatus: "",
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        totals: 0,
        total_page: 0,
      },
      STATUS_RETURN_RESULT,
      PRODUCT_TYPES,
      isShowSlideBar: false,
      data: [],
      fieldsTable: [
        {
          key: "name",
          label: "Tên Campaign",
        },
        // {
        //   key: "campaign_budget",
        //   label: "Ngân sách",
        // },
        {
          key: "start_date",
          label: "Ngày bắt đầu",
        },
        {
          key: "end_date",
          label: "Ngày kết thúc",
        },
      ],
      selectedCampaignID: 0,
    };
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refCampaignsTable
        ? this.$refs?.refCampaignsTable?.localItems?.length
        : 0;
      return {
        from:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.pagination.totals,
      };
    },
  },
  mounted() {
    this.fetchCampaigns();
    document.addEventListener('keydown', this.handleKeydown);
  },
  watch: {
    "pagination.perPage"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.fetchCampaigns();
    },
    "pagination.currentPage"() {
      this.fetchCampaigns();
    },
    "filter.searchQuery"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.fetchCampaigns();
    },
    "filter.returnResultStatus"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.fetchCampaigns();
    },
  },
  methods: {
    handleKeydown(event) {
        if (event.shiftKey && event.key === 'P') {
            this.isShowCheckCodeModal = true; // Call your modal open method
        }
    },
    handleRowClick(item) {
      this.$router.push({
        name: "campaigns-id",
        params: { id: item.id },
        query: {
          page: this.pagination.currentPage,
          total_page: this.pagination.total_page,
        },
      });
      if (this.filter.searchQuery) {
        this.$router.push({
          query: {
            search: this.filter.searchQuery,
          },
        });
      }
      // this.isShowSlideBar = true
      this.selectedCampaignID = item.id;
    },
    async fetchCampaigns(ctx, callback) {
      this.isLoading = true;
      const userData = this.appUtils.getLocalUser();
      const org_id = userData?.supplierInfo?.org_id;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getCampaigns({
          search: this.filter.searchQuery,
          page_size: this.pagination.perPage,
          page_num: this.pagination.currentPage,
          sort_by: "id",
          order: "desc",
          status: this.filter.returnResultStatus,
          org_id,
        })
        .then((response) => {
          const campaigns = response?.data?.data || [];
          this.pagination.totals = response.data?.page?.total;
          this.pagination.total_page = response.data?.page?.total_page;
          this.data = response.data?.data;
          callback && callback(campaigns);
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.v-select {
  .vs__dropdown-toggle {
    height: 100%;
  }
}
.cs-tr-table {
  height: 40px !important;
}

.cs-header-col * {
  word-break: break-word !important;
}

.cs-bg-row {
  background-color: #e2f7fe !important;
}

::v-deep.el-table {
  .el-table__row {
    height: 40px;
  }
}
</style>
