<template>
  <b-card no-body class="mb-0">
    <div class="m-2">
      <b-row>
        <b-col v-if="isShowTabs" cols="12">
          <b-tabs content-class="mb-2">
            <b-tab @click="(e) => $emit('setTab', e)" title="ALL" active></b-tab>
            <b-tab @click="(e) => $emit('setTab', e)" title="My following orders"></b-tab>
            <b-tab @click="(e) => $emit('setTab', e)" title="Floating orders"></b-tab>
          </b-tabs>
        </b-col>

        <b-col v-if="isShowStatusDropdown" cols="9" md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <StatusDropdown :current-status="currentStatus" :dropdownOptions="dropdownOptions"
            @setStatus="handleSetStatus" />
        </b-col>

        <b-col cols="12" :md="colsRight">
          <div class="d-flex align-items-center justify-content-end">
            <label class="w-100 mb-1 mb-md-0">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon size="16" icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input v-model="searchInput" debounce="500" class="d-inline-block"
                  :placeholder="searchPlaceholder" @update="handleChangeSearchQuery" />
              </b-input-group>
            </label>
          </div>
        </b-col>
        <b-col cols="12" :md="colsRight">
          <div class="d-flex align-items-center justify-content-end">
            <slot name="cta"></slot>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-overlay :show="isLoading" variant="light" :opacity="0.75" blur="2px">
      <slot></slot>
    </b-overlay>

    <Pagination class="mx-2 mb-2 mt-1" :meta="meta" :perPage="perPage" :currentPage="currentPage" :totals="totals"
      @setCurrentPage="handleSetCurrentPage" @setPerPage="handleSetPerPage" />
  </b-card>
</template>

<script>
import i18n from '@/libs/i18n'
import {
  BCard,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BRow
} from 'bootstrap-vue'
import Pagination from '../components/Pagination.vue'
import StatusDropdown from '../components/StatusDropdown.vue'

export default {
  name: 'LayoutDataTable',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BOverlay,
    Pagination,
    StatusDropdown
  },
  props: {
    dropdownOptions: {
      type: Array,
      default: () => [
        {
          value: null,
          statusName: i18n.t('lbl_on_sale.service')
        },
        {
          value: 50,
          statusName: i18n.t('lbl_off_sale.service')
        }
      ]
    },
    isShowStatusDropdown: {
      type: Boolean,
      default: false
    },
    isShowTabs: {
      type: Boolean,
      default: false
    },
    colsLeft: {
      type: Number,
      default: 6
    },
    colsRight: {
      type: Number,
      default: 6
    },
    perPage: Number,
    searchQuery: String,
    searchPlaceholder: String,
    isLoading: {
      type: Boolean,
      default: false
    },
    meta: {
      type: Object,
      default: () => { }
    },
    currentPage: Number,
    totals: Number
  },
  data() {
    return {
      searchInput: this.searchQuery,
      currentStatus: null
    }
  },
  methods: {
    handleSetStatus(value) {
      this.currentStatus = value
      this.$emit('setStatus', value)
    },
    handleChangeSearchQuery(value) {
      this.$emit('setSearchQuery', value)
    },
    handleSetPerPage(value) {
      this.$emit('setPerPage', value)
    },
    handleSetCurrentPage(value) {
      this.$emit('setCurrentPage', value)
    }
  }
}
</script>

<style></style>
