<template>
  <div class="mt-1">
    <b-modal
      size="md"
      :visible="isShowCheckCodeModal"
      title="Kiểm tra voucher code"
      centered
      cancel-variant="outline-secondary"
      :hide-footer="true"
      @hide="hideMethod"
      @change="(val) => $emit('update:is-show-check-code-modal', val)"
    >
      <b-overlay :show="loading" rounded="sm" spinner-variant="primary" spinner-type="grow" spinner-small>
        <div v-if="step === 1">
          <b-form-group label="Tìm kiếm">
            <b-form-input
              placeholder="Nhập voucher code"
              v-model="voucherCode"
              @input="resetVoucherInfo"
              autofocus
            />
          </b-form-group>
          <div class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="w-full"
              :loading="loading"
              @click="handleCheckVoucherCode"
            >
              Kiểm tra
            </b-button>
          </div>
        </div>

        <div v-if="step === 2 && infoVoucher.group" class="d-flex flex-col items-center justify-center text-center">
          <h4 :class="voucherStatusClass">{{ voucherStatusLabel }}</h4>
          <p>Mã voucher: {{ voucherCode }}</p>
          <p v-if="infoVoucher.campaign">{{ infoVoucher.campaign.name }}</p>
          <p v-if="voucherExpired">{{ voucherExpiredText }}</p>
          <p v-if="voucherNotStarted">{{ voucherNotStartedText }}</p>
          <p>Voucher giá trị: {{ voucherValue }} </p>

          <b-button
            v-if="!infoVoucher.is_redeemed && !voucherExpired && !voucherNotStarted"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="w-full"
            :loading="loading"
            @click="handleRedeemVoucherCode"
          >
            Sử dụng
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="w-full"
            @click="resetForm"
          >
            Quay lại
          </b-button>
        </div>

        <div v-if="step === 3" class="d-flex flex-col items-center justify-center">
          <IconSuccess />
          <h4 class="text-success text-center">Sử dụng voucher thành công</h4>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BFormGroup, BFormInput, BButton, BOverlay } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import appUtils from "@/utils/appUtils";
import IconSuccess from './IconSuccess';
import moment from 'moment';

export default {
  components: { BModal, BButton, BOverlay, BFormGroup, BFormInput, IconSuccess },
  directives: { "b-modal": VBModal, Ripple },
  props: { isShowCheckCodeModal: { type: Boolean, required: true } },
  data() {
    return {
      appUtils,
      step: 1,
      infoVoucher: {},
      voucherCode: "",
      loading: false,
      redeemVoucherCodeSuccess: false,
    };
  },
  watch: {
    isShowCheckCodeModal(newVal) {
      if (newVal) this.resetForm();
    },
  },
  computed: {
    voucherStatusLabel() {
      if (this.infoVoucher.is_redeemed) return "Voucher đã sử dụng";
      if (this.voucherExpired) return "Voucher đã hết hạn";
      if (this.voucherNotStarted) return "Chương trình chưa bắt đầu";
      return "Voucher hợp lệ";
    },
    voucherStatusClass() {
      return this.infoVoucher.is_redeemed || this.voucherExpired || this.voucherNotStarted ? "text-danger" : "text-success";
    },
    voucherExpired() {
      return this.infoVoucher.campaign?.end_date < Date.now();
    },
    voucherNotStarted() {
      return this.infoVoucher.campaign?.start_date > Date.now();
    },
    voucherExpiredText() {
      return `Chương trình khuyến mãi đã kết thúc vào ngày ${moment(this.infoVoucher.campaign.end_date).format('DD/MM/YYYY')}`;
    },
    voucherNotStartedText() {
      return `Chương trình khuyến mãi bắt đầu từ ngày ${moment(this.infoVoucher.campaign.start_date).format('DD/MM/YYYY')}`;
    },
    voucherValue() {
      if (this.infoVoucher.group.voucher_type === 1) {
        return `${appUtils.formatNumber(this.infoVoucher.group.voucher_value)} VNĐ`;
      }
      return `${appUtils.formatNumber(this.infoVoucher.group.voucher_value)}% - Giá trị tối đa ${appUtils.formatNumber(this.infoVoucher.group.max_voucher_value)}`;
    },
  },
  methods: {
    hideMethod() {
      this.$emit("closeModalCheckCode");
    },
    resetVoucherInfo() {
      this.infoVoucher = {};
    },
    resetForm() {
      this.step = 1;
      this.voucherCode = '';
      this.infoVoucher = {};
    },
    async handleCheckVoucherCode() {
      this.loading = true;
      try {
        const userData = appUtils.getLocalUser();
        const org_id = userData?.supplierInfo?.org_id;
        const { data } = await Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .checkVoucherCode(this.voucherCode, { org_id });
        this.infoVoucher = data;
        this.step = 2;
      } catch (error) {
        this.showToastError("Voucher code không hợp lệ");
      } finally {
        this.loading = false;
      }
    },
    async handleRedeemVoucherCode() {
      try {
        await Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .redeemVoucherCode(this.infoVoucher.id);
        this.step = 3;
      } catch (error) {
        this.showToastError("Lỗi khi sử dụng voucher");
      }
    },
    showToastError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.collapsible {
  overflow: hidden;
  height: 0;
  transition: height 100ms ease;
}

.collapsible.is-open {
  height: auto;
}

.content {
  padding: 10px;
}
</style>
